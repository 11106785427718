import React, { useEffect } from "react"
import { navigate, graphql, useStaticQuery } from "gatsby"
import { usePrismicPreview } from "gatsby-source-prismic"
import styled from "styled-components"

// Components
import Layout from "../components/global/layout"
import { StandardContainer } from "../components/common"

// Styles

const StyledPreviewLoader = styled(StandardContainer)`
  background: #000;
  color: #fff;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`

const PreviewPage = ({ location }) => {
  const { allPrismicStandardPage } = useStaticQuery(graphql`
    {
      allPrismicStandardPage {
        nodes {
          uid
        }
      }
    }
  `)
  const pageUIDs = allPrismicStandardPage.nodes.map(node => node.uid)

  const pubishedUIDs = pageUIDs

  const getPagePath = doc => {
    switch (doc.type) {
      case "standard_page":
        return `/${doc.uid}`
      default:
        return `/`
    }
  }

  const pathResolver = () => doc => {
    const previewedUID = doc.uid
    const path = getPagePath(doc)
    if (pubishedUIDs.includes(previewedUID)) {
      return path
    } else {
      return `/app/unpublished`
    }
  }

  const { previewData, path } = usePrismicPreview({
    // The repositoryName value from your `gatsby-config.js`.
    repositoryName: "catering-studio",
    pathResolver,
  })

  useEffect(() => {
    if (previewData && path) {
      window.__PRISMIC_PREVIEW_DATA__ = previewData
      navigate(path)
    }
  }, [previewData, path])

  return (
    <Layout>
      <StyledPreviewLoader>
        <h2>Loading preview...</h2>
      </StyledPreviewLoader>
    </Layout>
  )
}

export default PreviewPage
